import React from 'react'

import {
  Accordion,
  AccordionSection,
  Body2,
  COLORS,
  Layout,
} from 'ethos-design-system'
//@ts-ignore
import { useAccordionState } from 'ethos-design-system/src/components/Accordion/useAccordionState'
import { cn } from 'lib/client-utils'

import { cmsModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import Markdown from '../global/Markdown'
import styles from './AccordionFaq.module.scss'
import { MinusIcon } from './MinusIcon'
import { PlusIcon } from './PlusIcon'

interface AccordionFaqContentProps {
  heading: string
  faqs: Faq[]
  ctaLabel: string
  ctaMobileLabel: string
  clickthroughUrl: string
  minimalTheme: boolean
  variant?: 'wills'
}

interface Faq {
  title: string
  content: string
}

export const AccordionFaqContent = ({
  heading,
  faqs,
  ctaLabel,
  ctaMobileLabel,
  clickthroughUrl,
  minimalTheme = false,
}: AccordionFaqContentProps) => {
  const handleCtaClicked = (): void => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        ctaLabel,
        clickthroughUrl,
        module: 'AccordionFaq',
      },
    })
  }

  const { expanded, onToggle } = useAccordionState(
    { 1: false },
    (key: number, title: string, value: boolean) =>
      cmsModuleAnalytics.accordionExpanded({
        key,
        title,
        value: value ? 'expanded' : 'collapsed',
      }),
    true
  )

  const toggleChild = (expanded: boolean) => {
    return (
      <div className={styles.Toggle}>
        {expanded ? <MinusIcon /> : <PlusIcon />}
      </div>
    )
  }

  return (
    <div
      className={cn('flex flex-col justify-center pt-8 text-center md:pt-24', {
        [styles.minimalTheme]: minimalTheme,
      })}
    >
      <div className={styles.heading}>
        <Markdown input={heading} />
      </div>
      <div className="Accordion mb-0 flex flex-col items-center justify-center">
        <Accordion expanded={expanded} onToggle={onToggle}>
          {faqs.map((faq, index) => (
            <AccordionSection
              key={`faq-${faq.title}_${index}`}
              labelClassName={styles.Label}
              panelClassName={styles.Panel}
              toggleClassName={styles.Toggle}
              title={faq.title}
              renderToggle={toggleChild}
            >
              <Markdown className={styles.Markdown} input={faq.content} />
            </AccordionSection>
          ))}
        </Accordion>
      </div>
      {!minimalTheme && (
        <div className="mb-12 mt-10 md:mb-24">
          <a
            href={clickthroughUrl}
            className={styles.cta}
            onClick={handleCtaClicked}
          >
            <div className="hidden lg:block">
              <Body2.Regular400 color={COLORS.WHITE}>
                {ctaLabel}
              </Body2.Regular400>
            </div>
            <div className="block lg:hidden">
              <Body2.Regular400 color={COLORS.WHITE}>
                {ctaMobileLabel}
              </Body2.Regular400>
            </div>
          </a>
        </div>
      )}
    </div>
  )
}

export interface AccordionFaqProps {
  moduleData: AccordionFaqContentProps
}

const AccordionFaq = ({ moduleData }: AccordionFaqProps) => {
  return (
    <div className="relative">
      {!moduleData.minimalTheme && (
        <div className={styles.whiteCurveOverlay}>
          <div className={styles.inset} />
        </div>
      )}
      <div
        className={cn('relative', {
          'bg-gradient-to-b from-skyBlue to-transparent':
            !moduleData.minimalTheme,
        })}
      >
        <Layout.HorizontallyPaddedContainer>
          <AccordionFaqContent {...moduleData} />
        </Layout.HorizontallyPaddedContainer>
      </div>
    </div>
  )
}

export default AccordionFaq
