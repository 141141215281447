import React from 'react'

import AccordionFaq, { AccordionFaqProps } from './AccordionFaq'
import WillsAccordionFaq from './variants/WillsAccordionFaq'

const AccordionFaqWrapper = ({ moduleData }: AccordionFaqProps) => {
  if (moduleData.variant === 'wills') {
    return <WillsAccordionFaq moduleData={moduleData} />
  }

  return <AccordionFaq moduleData={moduleData} />
}

export default AccordionFaqWrapper
